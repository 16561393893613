import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { animated, useSpring } from 'react-spring'
import Brand from '../components/Brand'
import Breadcrumbs from '../components/Breadcrumbs'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import text from '../components/Text/text'

interface IGroup {
    children: any
}

const Group = ({ children }: IGroup) => (
    <div className="flex mt-4 md:mt-10 w-full group hover:opacity-70 transition duration-500">
        <div className="plain-gradient pt-px-2 pb-px-2 pl-px-2">
            <div className="bg-plain-gray w-1 h-full"></div>
        </div>
        {children}
        <div className="plain-gradient pt-px-2 pb-px-2 pr-px-2">
            <div className="bg-plain-gray w-1 h-full"></div>
        </div>
    </div>
)

const breadcrumbs = [
    {
        link: '/',
        title: 'Home',
    },
    {
        link: null,
        title: 'Careers',
    },
]

const CareersPage = (): ReactElement => {
    const animation = useSpring({
        delay: 700,
        opacity: 1,
        transform: 'translate3d(0,0px,0)',
        from: { opacity: 0, transform: 'translate3d(0,30px,0)' },
    })
    return (
        <>
            <SEO title="Careers" />
            <Layout>
                <animated.div
                    style={animation}
                    className="w-full max-w-3xl mx-auto text-white"
                >
                    <div className="flex px-4 py-4 md:py-8 text-white">
                        <Link to="/">
                            <Brand />
                        </Link>
                    </div>
                    <div className="p-4 overflow-hidden">
                        <Breadcrumbs steps={breadcrumbs} />
                        <h1 className={text.H1}>Work with us</h1>
                        <p className="text-lg mt-4 opacity-75">
                            We are currently recruiting for the following
                            positions
                        </p>
                    </div>
                    <div className="md:grid grid-cols-2 gap-8 p-4">
                        <div className="w-full">
                            <Link to="/job/java-developer">
                                <Group>
                                    <div className="w-full text-center text-2xl py-10">
                                        Java Developer
                                    </div>
                                </Group>
                            </Link>
                        </div>
                    </div>
                </animated.div>
            </Layout>
        </>
    )
}

export default CareersPage
